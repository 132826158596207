import React from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';

const Love = () => (
  <Layout>
    <Sidebar showTabs="love" />

    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="top"
    >
      <ol>
        <h3>The State of Love - منزلة المحبة </h3>
        <li>
          <Link to="/love/#quran">Intorudction and Step1: Quran</Link>
          <ol>
            <li>
              <Link to="/love/#baqarah">Surat Al Baqarah</Link>
              <span className={Classes.blink}>[11/6/2020]</span>
            </li>
          </ol>
        </li>
        <li>
          <Link to="#prophet">Love of the Prophet (PBUH)</Link>
          <span className={Classes.blink}>[11/13/2020]</span>
          <span className={Classes.blink_infinite}>
            <img
              className="img-fluid img-profile mx-auto mb-2,"
              src={arrow_left}
              alt=""
              height="30"
              width="30"
            />
          </span>
          <span>We are here</span>
          <ol></ol>
        </li>
      </ol>
    </section>
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="quran"
    >
      <ul>
        <h4>Intorudction and Step1: Quran</h4>
        <li>
          <a href="https://youtu.be/IEcdVoVC1pQ" target="blank">
            [English - YouTube Video] The State of Love - منزلة المحبة
          </a>
        </li>
        <li>
          <a href="https://myislam.net/static/Love.pdf" target="blank">
            [English - pdf] The State of Love - منزلة المحبة
          </a>
        </li>
        <li>
          <a
            href="https://myislam.net/static/Love_1_audio_only.m4a"
            target="blank"
          >
            [English - Audio] The State of Love - منزلة المحبة
          </a>
        </li>
        <li>
          References:
          <ul>
            <li>
              <a
                href="https://islamweb.net/ar/library/index.php?page=bookcontents&ID=529&bk_no=119&idfrom=478&idto=492"
                target="blank"
              >
                [Arabic] منزلة المحبة
              </a>
            </li>
            <p />
            <li>
              <a
                href="https://en.islamway.net/article/12343/loving-allah"
                target="blank"
              >
                [English] Stage of Love - ten reasons for gaining the love of
                Allah
              </a>
            </li>
            <p />
            <li>
              <a href="https://youtu.be/S1Y_ZndU55U" target="blank">
                [Arabic - YouTube] حب الله للعبد | عمرو خالد
              </a>
            </li>
            <p />
            <p />
            <li>
              <a href="https://youtu.be/0W6BAR8Un_Y" target="blank">
                [Arabic - YouTube] حب العبد لله | عمرو خالد
              </a>
            </li>
            <p />
            <p />
            <li>
              <a
                href="https://ar.islamway.net/book/21656/%D8%B1%D9%88%D8%B6%D8%A9-%D8%A7%D9%84%D9%85%D8%AD%D8%A8%D9%8A%D9%86-%D8%B7-%D8%B9%D8%A7%D9%84%D9%85-%D8%A7%D9%84%D9%81%D9%88%D8%A7%D8%A6%D8%AF"
                target="blank"
              >
                [Arabic - Book] روضة المحبين
              </a>
            </li>
            <p />
            <p />
            <li>
              <a
                href="https://MyIslam.net/static/15_IbnQeyym_RoudtMohbeen.pdf"
                target="blank"
              >
                [Arabic - PDF] روضة المحبين
              </a>
            </li>
            <p />
            <p />
            <li>
              <a
                href="https://MyIslam.net/static//QuranAmrKhaled.mp3"
                target="blank"
              >
                [Arabic - mp3] فضل القران - عمرو خالد
              </a>
            </li>
            <li>
              <a
                href="https://myislam.net/static/QuranicReflection.pdf"
                target="blank"
              >
                Quranic Reflection
              </a>
            </li>
            <p />
          </ul>
        </li>
      </ul>
    </section>
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="baqarah"
    >
      <ul>
        <h4>Surat Al Baqarah</h4>
        <ol>
          <li>
            <a href="https://youtu.be/s_bJcW01IMo" target="blank">
              Introduction: Virtues and Importance of of Surat Al Baqarah
            </a>
          </li>
          <li>
            <a href="https://youtu.be/sUkmIJy_7Sg" target="blank">
              Practiciing Surat Al-Baqarah - 1
            </a>
          </li>
          <li>
            <a href="https://youtu.be/c165PaEVR70" target="blank">
              Practiciing Surat Al-Baqarah - 2 [11/6/2020]
            </a>
          </li>
        </ol>
        <li>
          <h4>References:</h4>
          <ul>
            <li>
              <a href="https://youtu.be/9wonJC5lHnA" target="blank">
                (English - YouTube Video) - Importance of Surat Al-Baqarah
              </a>
            </li>

            <li>
              <a
                href="https://aboutislam.net/counseling/ask-about-islam/virtues-surat-al-baqarah/"
                target="blank"
              >
                [English - PDF] Virtues of Surat Al Baqarah
              </a>
            </li>

            <li>
              <a
                href="https://myislam.net/static/QuranicReflection.pdf"
                target="blank"
              >
                [English - PDF] Quranic Reflection - Amr Khalid
              </a>
            </li>
            <li>
              <a
                href="https://www.muslim-library.com/dl/books/english_Tajweed_Rules_of_the_Quran.pdf"
                target="blank"
              >
                Rules of Tajweed
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="pray1"
    >
      <ul>
        <h4>Step2_1: Obligatory Prayers</h4>
        <li>References:</li>
        <ul>
          <li>
            <a href="https://ar.wikipedia.org/wiki/%D8%A7%D9%84%D8%B5%D9%84%D8%A7%D8%A9_%D9%81%D9%8A_%D8%A7%D9%84%D8%A5%D8%B3%D9%84%D8%A7%D9%85#:~:text=17%20%D9%88%D8%B5%D9%84%D8%A7%D8%AA%20%D8%AE%D8%A7%D8%B1%D8%AC%D9%8A%D8%A9-,%D9%85%D9%86%D8%B2%D9%84%D8%A9%20%D8%A7%D9%84%D8%B5%D9%84%D8%A7%D8%A9,%D8%A7%D9%84%D9%82%D9%8A%D8%A7%D9%85%D8%A9%20%D9%88%D9%82%D8%AF%20%D9%81%D8%B1%D8%B6%D8%AA%20%D9%84%D9%8A%D9%84%D8%A9%20%D8%A7%D9%84%D9%85%D8%B9%D8%B1%D8%A7%D8%AC.&text=%D9%88%D9%82%D8%A7%D9%84%20%D8%B9%D8%A8%D8%AF%20%D8%A7%D9%84%D9%84%D9%87%20%D8%A8%D9%86%20%D9%82%D8%B1%D8%B7,%D9%88%D8%A5%D9%86%20%D9%81%D8%B3%D8%AF%D8%AA%20%D9%81%D8%B3%D8%AF%20%D8%B3%D8%A7%D8%A6%D8%B1%20%D8%B9%D9%85%D9%84%D9%87.%C2%BB">
              [Arabic] الصلاة في الإسلام https://ar.wikipedia.org/
            </a>
          </li>
          <li>
            <a href="http://en.alukah.net/Shariah/0/774/">
              [English] Salah and its status in Islam
            </a>
          </li>
          <li>
            <a href="https://myislam.net/static/pray_1_Amr.mp3">
              [Arabic - mp3] Amr Khalid - Prayers 1
            </a>
          </li>
          <li>
            <a href="https://youtu.be/hJYJf13u-N4" target="blank">
              [Arabic - mp3] Amr Khalid - Prayers 1 أهمية الصلاة
            </a>
          </li>
          <li>
            <a href="https://youtu.be/6ehrr7E8xnU" target="blank">
              [Arabic - mp3] رحلة للسعادة - الحلقة 22 - الصلاة
            </a>
          </li>
        </ul>
      </ul>
    </section>
    <section
      className="resume-section p-3 p-lg-5 d-flex align-items-center"
      id="prophet"
    >
      <ol>
        <li>
          <a href="https://youtu.be/DTBQl0fp-EU" target="blank">
            Intorudction [YouTube Video]
          </a>
          <span className={Classes.blink}>[11/13/2020]</span>
          <span className={Classes.blink_infinite}>
            <img
              className="img-fluid img-profile mx-auto mb-2,"
              src={arrow_left}
              alt=""
              height="30"
              width="30"
            />
          </span>
          <span>We are here</span>
        </li>
      </ol>
    </section>
    <section></section>
  </Layout>
);

export default Love;
